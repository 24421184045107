import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              Welcome to laws.rocks. While we strive for accuracy and authenticity in every piece of content, we acknowledge the dynamic nature of the digital world. Therefore, laws.rocks provides all content 'as is' and makes no warranty, either expressed or implied, concerning the originality, completeness, or up-to-date nature of the information provided.
            </p>
            <ul className="lh-lg">
              <li>
                The information, advice, and views expressed on laws.rocks do not constitute professional or legal advice. Users should consult an appropriate professional for specific advice tailored to their situation.
              </li>
              <li>
                Users agree not to hold laws.rocks liable for any direct, indirect, incidental, punitive, or consequential damages arising from using, relying on, or in connection with the content found on the site.
              </li>
              <li>
                We reserve the right to update, change, or replace any part of these disclaimers by posting updates and/or changes to laws.rocks. It is your responsibility to check for updates periodically.
              </li>
              <li>
                Laws.rocks contains links to third-party websites that are not provided or maintained by us. We do not endorse, sponsor, or approve any third-party website or the information, products, or services offered on them.
              </li>
              <li>
                The copyright of all content, icons, logos, images, documents, and other materials on laws.rocks belongs to laws.rocks unless stated otherwise. Unauthorized use of any of the content may violate copyright, trademark, and other laws.
              </li>
              <li>
                These disclaimers are governed by and interpreted in accordance with the laws. Your continued use of laws.rocks denotes your agreement to abide by these terms.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;