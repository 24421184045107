import React from "react";

const TermsAndConditions = ({ darkTheme }) => { 
  return ( 
    <div id="terms-policy" className="modal fade" role="dialog" aria-hidden="true" > 
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document" > 
        <div className={ "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "") } > 
          <div className="modal-header"> 
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}> Terms & Policy </h5> 
            <button type="button" className={"btn-close " + (darkTheme ? "btn-close-white" : "")} data-bs-dismiss="modal" aria-label="Close" /> 
          </div> 
          <div className="modal-body p-4"> 
            <p> Welcome to laws.rocks, the digital abode of Will Laws! By accessing this website, you agree to the following whimsical yet entirely fictional terms and conditions: </p> 
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}> Terms of Use </h3> 
            <p> 1. You must agree to appreciate the subtle humor and clever wordplay presented by Will Laws on this website. Failure to comply may result in an onslaught of puns. </p> 
            <p> 2. Any attempts to take this website too seriously will be met with absolutely nothing as I don't collect any information about you. </p>
            <p> 3. Will Laws reserves the right to change the meaning and interpretation of any terms and conditions at any time, purely for the sake of his own amusement. </p> 
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}> Privacy Policy </h3> 
            <p> 1. Will Laws pledges not to collect any viewer information because he respects personal freedom and the right to browse incognito. </p> 
            <p> 2. No cookies will be used on this website. Instead, Will Laws will provide you with a virtual gastronomic delight through an immersive digital experience. </p> 
            <p> 3. Any personal information you inadvertently disclose during your visit, such as your preferred coffee blend or your first concert, will be promptly forgotten by Will Laws in the spirit of maintaining absolute privacy. </p> 
            <p> By continuing to use laws.rocks, you acknowledge that you have read and agree to these delightfully absurd terms and conditions. Enjoy your visit and prepare for a journey through the labyrinth of Will's wit! </p> 
          </div> 
        </div> 
      </div> 
    </div> 
  ); 
};

export default TermsAndConditions;